<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
    <g>
      <rect
        class="cls-1"
        x="54.3404287635"
        y="54.9369083168"
        width="163.0495765903"
        height="390.1261833664"
        fill="#44299e"
      />
    </g>
    <g class="logo animated">
      <polygon
        class="cls-2"
        points="341.6162591474 102.687917044 282.609873581 161.6945870748 386.6523832159 161.694658191 386.6523832159 265.7371678258 445.6591243628 206.7307111433 445.6591243628 102.687917044 341.6162591474 102.687917044"
        fill="#6347f5"
      />
    </g>
    <g class="col animated">
      <rect
        x="282.6098735746"
        y="314.6521989404"
        width="163.0496976674"
        height="130.4108926467"
        fill="#6347f5"
      />
    </g>
  </svg>
</template>

<style lang="scss" scoped>
@keyframes logoAnimate {
  0% {
    transform: translate(0, 0);
  }
  70% {
    transform: translate(0, -100px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes colAnimate {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.6);
  }
  100% {
    transform: scale(1, 1);
  }
}

.logo {
  animation-name: logoAnimate;
  animation-duration: 0.6s;
  animation-iteration-count: 1;
}

.col {
  transform-origin: left calc(100% - 50px);
  animation-name: colAnimate;
  animation-duration: 0.6s;
  animation-iteration-count: 1;
}
</style>
