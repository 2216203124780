import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BlockHero = _resolveComponent("BlockHero")!
  const _component_BlockSlider = _resolveComponent("BlockSlider")!
  const _component_BlockMosaic = _resolveComponent("BlockMosaic")!
  const _component_BlockMembers = _resolveComponent("BlockMembers")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BlockHero),
    _createVNode(_component_BlockSlider, {
      "slider-data": _ctx.slider,
      title: _ctx.$t('foundation_slider_title')
    }, null, 8, ["slider-data", "title"]),
    _createVNode(_component_BlockMosaic, {
      "mosaic-data": _ctx.mosaicFirst,
      title: _ctx.$t('foundation_network_title')
    }, null, 8, ["mosaic-data", "title"]),
    _createVNode(_component_BlockMosaic, {
      "mosaic-data": _ctx.mosaicSecond,
      title: _ctx.$t('foundation_promotion_title')
    }, null, 8, ["mosaic-data", "title"]),
    _createVNode(_component_BlockMembers)
  ], 64))
}