
import { defineComponent } from 'vue'
import CoinmarketcapIcon from '@/components/Icons/CoinmarketcapIcon.vue'
import TelegramIcon from '@/components/Icons/TelegramIcon.vue'
import DiscordIcon from '@/components/Icons/DiscordIcon.vue'
import GithubIcon from '@/components/Icons/GithubIcon.vue'
import TwitterIcon from '@/components/Icons/TwitterIcon.vue'
import YoutubeIcon from '@/components/Icons/YoutubeIcon.vue'
import MailIcon from '@/components/Icons/MailIcon.vue'

interface Nav {
  name: string
  link: string
  isDownload?: boolean
}

export default defineComponent({
  name: 'FooterBlock',
  components: {
    CoinmarketcapIcon,
    TelegramIcon,
    DiscordIcon,
    GithubIcon,
    TwitterIcon,
    YoutubeIcon,
    MailIcon,
  },
  data() {
    return {
      socials: [
        {
          image: 'Telegram',
          link: 'https://t.me/everscale',
        },
        {
          image: 'Youtube',
          link: 'https://www.youtube.com/channel/UCPVBkzUuMD5TlQDGUqyWzzg',
        },
        {
          image: 'Discord',
          link: 'https://discord.com/invite/GSPAGVTuqQ',
        },
        {
          image: 'Coinmarketcap',
          link: 'https://coinmarketcap.com/gravity/profile/Everscale',
        },
        {
          image: 'Twitter',
          link: 'https://twitter.com/Everscale_net',
        },
        {
          image: 'Github',
          link: 'https://github.com/tonlabs/everdev/blob/main/docs/guides/quick-start.md',
        },
      ],
    }
  },
  computed: {
    nav(): Nav[] {
      return [
        {
          name: 'Everscale',
          link: 'https://everscale.network',
        },
        {
          name: this.$t('foundation_footer_whitepaper'),
          link: '/docs/everscale-whitepaper.pdf',
          isDownload: true,
        },
        {
          name: this.$t('foundation_footer_litepaper'),
          link: '/docs/everscale-litepaper_v1.02-c.pdf',
          isDownload: true,
        },
        {
          name: this.$t('foundation_footer_docs'),
          link: 'https://docs.everscale.network',
        },
      ]
    },
  },
})
