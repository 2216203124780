<template>
  <svg
    width="100"
    height="100"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
  >
    <g>
      <g class="bottom animate">
        <polygon
          points="52.341069455 332.3917747108 250.0002087126 233.562205082 447.6593479702 332.3917747108 250.0002087126 431.2213443396 52.341069455 332.3917747108"
          fill="#44299e"
        />
        <path
          d="M250,248.8533870247l167.0781580928,83.5390660019-167.0781580928,83.5390529573-167.0781580928-83.5390529573,167.0781580928-83.5390660019m0-30.5853380394l-12.2341039089,6.1169997763-167.0781580928,83.5390660019-48.9366243481,24.4683382631,48.9366243481,24.4683382631,167.0781580928,83.5390529573,12.2341039089,6.1169997763,12.2341039089-6.1169997763,167.0781580928-83.5390529573,48.9366243481-24.4683382631-48.9366243481-24.4683382631-167.0781580928-83.5390660019-12.2341039089-6.1169997763h0Z"
          fill="#13173e"
        />
      </g>
      <g>
        <polygon
          points="52.341069455 249.9987363107 250.0002087126 151.1691666819 447.6593479702 249.9987363107 250.0002087126 348.8349847413 52.341069455 249.9987363107"
          fill="#44299e"
        />
        <path
          d="M250,166.4609356287l167.0781580928,83.5390660019-167.0781580928,83.5390660019L82.9218419072,250.0000016306l167.0781580928-83.5390660019m0-30.5853347783l-12.2341039089,6.1169965152L70.6877379983,225.5316633675l-48.9366243481,24.4683382631,48.9366243481,24.4683382631,167.0781580928,83.5390660019,12.2341039089,6.1169997763,12.2341039089-6.1169997763,167.0781580928-83.5390660019,48.9366243481-24.4683382631-48.9366243481-24.4683382631-167.0781580928-83.5390660019-12.2341039089-6.1169965152h0Z"
          fill="#13173e"
        />
      </g>
      <g class="top animate">
        <polygon
          points="52.341069455 167.6090373115 250.0002087126 68.7727888809 447.6593479702 167.6090373115 250.0002087126 266.4386069403 52.341069455 167.6090373115"
          fill="#6347f5"
        />
        <path
          d="M250,84.0684907549l167.0781580928,83.5390660019-167.0781580928,83.5390594796L82.9218419072,167.6075567568,250,84.0684907549m0-30.5853347783l-12.2341039089,6.1169965152L70.6877379983,143.1392184937l-48.9366243481,24.4683382631,48.9366243481,24.4683382631,167.0781580928,83.5390594796,12.2341039089,6.1169997763,12.2341039089-6.1169997763,167.0781580928-83.5390594796,48.9366243481-24.4683382631-48.9366243481-24.4683382631L262.2341039089,59.6001524918l-12.2341039089-6.1169965152h0Z"
          fill="#13173e"
        />
      </g>
    </g>
  </svg>
</template>

<style lang="scss" scoped>
@keyframes topAnimate {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 25px);
  }
}

@keyframes bottomAnimate {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, -25px);
  }
}
.top {
  animation-name: topAnimate;
}

.bottom {
  animation-name: bottomAnimate;
}

.animate {
  animation-duration: 0.3s;
  animation-direction: alternate;
  animation-iteration-count: 2;
}
</style>
