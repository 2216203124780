import { createI18n } from 'vue-i18n'

const enLocales = require('@/locales/en.json')
const koLocales = require('@/locales/ko.json')

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: enLocales,
    ko: koLocales,
  },
})

export default i18n
