import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayersIcon = _resolveComponent("LayersIcon")!
  const _component_FeesIcon = _resolveComponent("FeesIcon")!
  const _component_BlockchainIcon = _resolveComponent("BlockchainIcon")!
  const _component_CrosschainIcon = _resolveComponent("CrosschainIcon")!
  const _component_RocketIcon = _resolveComponent("RocketIcon")!
  const _component_JoystickIcon = _resolveComponent("JoystickIcon")!

  return (_ctx.imageName === 'LayersIcon')
    ? (_openBlock(), _createBlock(_component_LayersIcon, { key: 0 }))
    : (_ctx.imageName === 'FeesIcon')
      ? (_openBlock(), _createBlock(_component_FeesIcon, { key: 1 }))
      : (_ctx.imageName === 'BlockchainIcon')
        ? (_openBlock(), _createBlock(_component_BlockchainIcon, { key: 2 }))
        : (_ctx.imageName === 'CrosschainIcon')
          ? (_openBlock(), _createBlock(_component_CrosschainIcon, { key: 3 }))
          : (_ctx.imageName === 'RocketIcon')
            ? (_openBlock(), _createBlock(_component_RocketIcon, { key: 4 }))
            : (_ctx.imageName === 'JoystickIcon')
              ? (_openBlock(), _createBlock(_component_JoystickIcon, { key: 5 }))
              : _createCommentVNode("", true)
}