<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_7783_146607)">
      <path
        d="M22.5078 6.37141C22.2788 5.36741 21.6008 4.57541 20.7388 4.30341C19.1798 3.81641 12.9248 3.81641 12.9248 3.81641C12.9248 3.81641 6.6728 3.81641 5.1108 4.30341C4.2518 4.57141 3.5738 5.36341 3.3418 6.37141C2.9248 8.19341 2.9248 11.9984 2.9248 11.9984C2.9248 11.9984 2.9248 15.8034 3.3418 17.6254C3.5708 18.6294 4.2488 19.4214 5.1108 19.6934C6.6728 20.1804 12.9248 20.1804 12.9248 20.1804C12.9248 20.1804 19.1798 20.1804 20.7388 19.6934C21.5978 19.4254 22.2758 18.6334 22.5078 17.6254C22.9248 15.8034 22.9248 11.9984 22.9248 11.9984C22.9248 11.9984 22.9248 8.19341 22.5078 6.37141Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.9268 15L16.1228 12L10.9268 9V15Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7783_146607">
        <rect
          width="24"
          height="24"
          fill="currentColor"
          transform="translate(0.924805)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
