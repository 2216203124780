<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_7783_146611)">
      <path
        d="M16.4714 20.1482V18.9233C16.4714 18.9233 16.4915 17.0067 15.438 15.3869C17.8394 15.219 19.7453 13.0766 19.7453 10.4317C19.7453 9.50362 19.3918 8.63815 18.7888 7.89328L18.7273 5.60901C18.7119 5.02849 18.1314 4.63241 17.5852 4.82986L15.1318 5.71661C14.439 5.5428 13.6976 5.44467 12.9244 5.44467C12.1512 5.44467 11.4098 5.5428 10.717 5.71542L8.26364 4.82867C7.7174 4.63122 7.13687 5.02731 7.1215 5.60783L7.06002 7.89328C6.45585 8.63815 6.10352 9.50362 6.10352 10.4317C6.10352 13.0766 8.00944 15.219 10.4108 15.3869C9.5224 16.5614 9.63532 17.4864 9.5224 18.1876C9.42875 18.7693 6.68381 17.1703 6.68381 17.1703C6.68381 17.1703 6.81112 18.1525 7.78666 19.0767C8.7622 20.0009 9.3774 20.1482 9.3774 20.1482"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7783_146611">
        <rect
          width="24"
          height="24"
          fill="currentColor"
          transform="translate(0.924805)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
