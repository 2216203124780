
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BlockMembers',
  data() {
    return {
      members: [
        {
          image: 'avatar-1',
          name: 'Mr. Panic',
          position: 'Cryptocurrency analyst',
        },
        {
          image: 'avatar-2',
          name: 'Yorik Sigismund',
          position: 'Content Manager',
        },
        {
          image: 'avatar-3',
          name: 'Vitaliy Volochai',
          position: 'Smart Contracts Developer',
        },
        {
          image: 'avatar-4',
          name: 'Lzhedmitriy II',
          position: 'SMM Specialist',
        },
        {
          image: 'avatar-5',
          name: 'Mr. Batman',
          position: 'Blockchain engineer',
        },
        {
          image: 'avatar-6',
          name: 'Just Sanya',
          position: 'Executive Director',
        },
        {
          image: 'avatar-7',
          name: 'Dmitro Gordon',
          position: 'Investor',
        },
        {
          image: 'avatar-8',
          name: 'Human',
          position: 'Customer Service Manager',
        },
      ],
    }
  },
})
