<template>
  <path
    class="center"
    d="M1404.21 1477.41L1461.98 1461.98L1492.85 1346.55L1408.41 1262L1350.64 1277.55L1292.98 1292.98L1277.55 1350.64L1262 1408.41L1304.33 1450.63L1346.55 1492.85L1404.21 1477.41Z"
    fill="#7642FF"
  />
  <path
    class="circle_1"
    d="M1152.09 1603.91L1194.31 1646.13L1251.96 1630.69L1309.73 1615.26L1312.68 1604.25L1340.6 1499.84L1298.38 1457.61L1256.16 1415.28L1198.39 1430.83L1140.74 1446.27L1109.75 1561.69L1152.09 1603.91Z"
    fill="#5E35CC"
  />
  <path
    class="circle_1"
    d="M1518.45 1671.21L1533.89 1613.44L1549.32 1555.79L1543.42 1549.89L1464.88 1471.23L1407.11 1486.67L1349.46 1502.22L1318.47 1617.64L1403.03 1702.08L1518.45 1671.21Z"
    fill="#5E35CC"
  />
  <path
    class="circle_1"
    d="M1555.79 1549.32L1613.45 1533.88L1671.21 1518.45L1702.09 1403.02L1617.64 1318.47L1559.88 1333.9L1502.22 1349.45L1486.67 1407.11L1471.24 1464.88L1552.61 1546.14L1555.79 1549.32Z"
    fill="#5E35CC"
  />
  <path
    class="circle_1"
    d="M1557.49 1325.16L1615.26 1309.73L1646.13 1194.3L1561.69 1109.75L1503.92 1125.3L1446.27 1140.73L1430.83 1198.39L1415.28 1256.16L1457.62 1298.38L1499.84 1340.6L1557.49 1325.16Z"
    fill="#5E35CC"
  />
  <path
    class="circle_1"
    d="M1295.32 1069.35L1237.55 1084.78L1222.12 1142.55L1206.68 1200.21L1248.9 1242.43L1291.12 1284.76L1348.89 1269.21L1406.54 1253.78L1437.53 1138.35L1352.97 1053.91L1295.32 1069.35Z"
    fill="#5E35CC"
  />
  <path
    class="circle_1"
    d="M1142.55 1222.12L1084.79 1237.55L1053.92 1352.97L1138.36 1437.53L1196.12 1421.98L1253.78 1406.54L1269.22 1348.89L1284.76 1291.12L1242.43 1248.9L1200.21 1206.68L1142.55 1222.12Z"
    fill="#5E35CC"
  />
  <path
    class="circle_2"
    d="M1766.21 1381.12L1823.98 1365.68L1854.85 1250.26L1770.41 1165.7L1712.64 1181.14L1654.98 1196.69L1639.44 1254.34L1624 1312.11L1666.22 1354.33L1708.55 1396.55L1766.21 1381.12Z"
    fill="#4C2BA7"
  />
  <path
    class="circle_2"
    d="M1714.46 956.984L1656.69 972.533L1599.03 987.969L1583.6 1045.62L1568.05 1103.39L1610.38 1145.61L1652.6 1187.83L1710.26 1172.4L1768.03 1156.96L1798.9 1041.54L1714.46 956.984Z"
    fill="#4C2BA7"
  />
  <path
    class="circle_2"
    d="M1365.69 1823.98L1396.56 1708.55L1312.12 1624L1196.69 1654.98L1165.71 1770.41L1250.26 1854.85L1365.69 1823.98Z"
    fill="#4C2BA7"
  />
  <path
    class="circle_2"
    d="M1505.74 901.148L1448.08 916.584L1390.31 932.019L1374.88 989.788L1359.44 1047.44L1401.66 1089.66L1443.88 1132L1501.65 1116.45L1559.31 1101.01L1590.29 985.589L1547.96 943.369L1505.74 901.148Z"
    fill="#4C2BA7"
  />
  <path
    class="circle_2"
    d="M1727.05 1727.05L1742.49 1669.4L1758.04 1611.63L1673.48 1527.19L1615.83 1542.62L1558.06 1558.06L1542.62 1615.83L1527.19 1673.48L1611.63 1758.04L1727.05 1727.05Z"
    fill="#4C2BA7"
  />
  <path
    class="circle_2"
    d="M1041.54 1798.9L1156.97 1768.03L1187.84 1652.6L1103.4 1568.05L1045.63 1583.6L987.974 1599.03L956.99 1714.46L1041.54 1798.9Z"
    fill="#4C2BA7"
  />
  <path
    class="circle_2"
    d="M1822.16 1589.72L1879.82 1574.29L1910.8 1458.86L1826.25 1374.42L1768.59 1389.86L1710.82 1405.29L1679.95 1520.72L1764.39 1605.27L1822.16 1589.72Z"
    fill="#4C2BA7"
  />
  <path
    class="circle_2"
    d="M1574.29 1879.82L1605.27 1764.39L1520.72 1679.95L1405.3 1710.82L1374.42 1826.25L1458.87 1910.8L1574.29 1879.82Z"
    fill="#4C2BA7"
  />
  <path
    class="circle_2"
    d="M1086.6 1013.4L1028.95 1028.95L997.962 1144.37L1082.52 1228.81L1140.17 1213.37L1197.94 1197.83L1228.81 1082.51L1144.37 997.961L1086.6 1013.4Z"
    fill="#4C2BA7"
  />
  <path
    class="circle_2"
    d="M1297.13 845.195L1239.37 860.631L1181.71 876.179L1150.73 991.604L1192.95 1033.82L1235.28 1076.04L1292.93 1060.61L1350.7 1045.06L1366.14 987.405L1381.57 929.749L1339.35 887.415L1297.13 845.195Z"
    fill="#4C2BA7"
  />
  <path
    class="circle_2"
    d="M933.837 1166.16L876.181 1181.71L845.197 1297.14L929.751 1381.58L987.407 1366.14L1045.06 1350.7L1060.61 1292.94L1076.05 1235.28L1033.83 1192.95L991.606 1150.73L933.837 1166.16Z"
    fill="#4C2BA7"
  />
  <path
    class="circle_2"
    d="M985.591 1590.29L1043.36 1574.75L1101.02 1559.31L1132 1443.89L1089.67 1401.67L1047.45 1359.45L989.79 1374.88L932.021 1390.32L901.15 1505.74L985.591 1590.29Z"
    fill="#4C2BA7"
  />
  <path
    class="circle_3"
    d="M2007.61 1097.49L1965.39 1055.16L1923.17 1012.94L1865.4 1028.37L1807.75 1043.92L1792.2 1101.58L1776.76 1159.35L1818.98 1201.57L1861.32 1243.79L1918.97 1228.35L1976.74 1212.92L1992.18 1155.15L2007.61 1097.49Z"
    fill="#3B2280"
  />
  <path
    class="circle_3"
    d="M725.12 1110.31L667.465 1125.75L652.029 1183.41L636.48 1241.17L678.814 1283.39L721.034 1325.61L778.69 1310.18L836.459 1294.74L851.894 1236.97L867.329 1179.32L825.109 1137.1L782.889 1094.77L725.12 1110.31Z"
    fill="#3B2280"
  />
  <path
    class="circle_3"
    d="M692.434 1449.89L776.987 1534.33L834.643 1518.9L892.298 1503.46L907.847 1445.69L923.282 1388.04L881.062 1345.7L838.729 1303.48L781.073 1318.92L723.418 1334.47L707.869 1392.12L692.434 1449.89Z"
    fill="#3B2280"
  />
  <path
    class="circle_3"
    d="M877.885 957.557L820.229 972.992L789.245 1088.42L873.799 1172.86L931.454 1157.42L989.223 1141.99L1004.66 1084.22L1020.09 1026.56L977.874 984.341L935.654 942.008L877.885 957.557Z"
    fill="#3B2280"
  />
  <path
    class="circle_3"
    d="M1600.85 763.818L1543.08 779.253L1527.64 837.022L1512.21 894.678L1554.43 936.898L1596.65 979.232L1654.42 963.683L1712.07 948.248L1743.06 832.823L1658.5 748.383L1600.85 763.818Z"
    fill="#3B2280"
  />
  <path
    class="circle_3"
    d="M1951.66 888.773L1867.22 804.219L1809.45 819.768L1751.8 835.203L1736.36 892.858L1720.81 950.627L1763.15 992.847L1805.37 1035.07L1863.02 1019.63L1920.79 1004.2L1936.23 946.428L1951.66 888.773Z"
    fill="#3B2280"
  />
  <path
    class="circle_3"
    d="M1241.18 636.477L1183.41 652.025L1125.76 667.461L1094.77 782.885L1179.33 867.325L1236.98 851.89L1294.75 836.455L1310.19 778.686L1325.62 721.03L1283.4 678.81L1241.18 636.477Z"
    fill="#3B2280"
  />
  <path
    class="circle_3"
    d="M1030.65 804.791L972.993 820.226L942.009 935.651L1026.56 1020.09L1084.22 1004.66L1141.99 989.22L1157.42 931.451L1172.86 873.796L1130.64 831.576L1088.42 789.242L1030.65 804.791Z"
    fill="#3B2280"
  />
  <path
    class="circle_3"
    d="M1449.9 692.43L1392.13 707.865L1334.47 723.414L1318.93 781.069L1303.49 838.725L1345.71 881.058L1388.04 923.279L1445.7 907.843L1503.47 892.294L1518.9 834.639L1534.34 776.983L1492.12 734.65L1449.9 692.43Z"
    fill="#3B2280"
  />
  <path
    class="circle_3"
    d="M888.779 1951.65L1004.2 1920.78L1019.64 1863.01L1035.07 1805.36L950.634 1720.8L835.21 1751.79L804.226 1867.21L888.779 1951.65Z"
    fill="#3B2280"
  />
  <path
    class="circle_3"
    d="M1212.92 1976.74L1243.79 1861.31L1159.35 1776.76L1043.93 1807.74L1012.94 1923.17L1097.5 2007.61L1212.92 1976.74Z"
    fill="#3B2280"
  />
  <path
    class="circle_3"
    d="M1421.53 2032.58L1452.51 1917.16L1367.96 1832.71L1252.53 1863.59L1221.66 1979.01L1306.1 2063.56L1421.53 2032.58Z"
    fill="#3B2280"
  />
  <path
    class="circle_3"
    d="M1630.24 2088.53L1661.11 1973.11L1576.67 1888.67L1461.25 1919.54L1430.26 2034.96L1514.82 2119.4L1630.24 2088.53Z"
    fill="#3B2280"
  />
  <path
    class="circle_3"
    d="M1783.01 1935.77L1813.88 1820.35L1729.44 1735.91L1614.01 1766.78L1598.58 1824.43L1583.03 1882.2L1667.58 1966.64L1783.01 1935.77Z"
    fill="#3B2280"
  />
  <path
    class="circle_3"
    d="M2088.54 1630.24L2119.41 1514.81L2034.97 1430.26L1977.2 1445.81L1919.54 1461.24L1888.67 1576.67L1973.11 1661.11L2088.54 1630.24Z"
    fill="#3B2280"
  />
  <path
    class="circle_3"
    d="M1935.77 1783L1951.21 1725.23L1966.64 1667.58L1924.42 1625.36L1882.2 1583.02L1824.43 1598.57L1766.78 1614.01L1751.34 1671.66L1735.91 1729.43L1820.35 1813.87L1935.77 1783Z"
    fill="#3B2280"
  />
  <path
    class="circle_3"
    d="M832.827 1743.05L948.251 1712.07L963.687 1654.41L979.236 1596.64L894.682 1512.2L837.026 1527.64L779.257 1543.07L748.387 1658.5L832.827 1743.05Z"
    fill="#3B2280"
  />
  <path
    class="circle_3"
    d="M1974.93 1436.96L2032.58 1421.52L2048.13 1363.87L2063.57 1306.1L2021.35 1263.88L1979.01 1221.66L1921.36 1237.09L1863.59 1252.53L1848.15 1310.3L1832.72 1367.95L1874.94 1410.17L1917.16 1452.51L1974.93 1436.96Z"
    fill="#3B2280"
  />
  <path
    class="circle_4"
    d="M1544.89 555.099L1487.24 570.648L1456.25 685.959L1540.81 770.513L1598.46 755.078L1656.23 739.529L1687.1 624.104L1602.66 539.664L1544.89 555.099Z"
    fill="#281758"
  />
  <path
    class="circle_4"
    d="M2131.78 1068.9L2074.12 1084.33L2016.35 1099.77L2000.92 1157.54L1985.48 1215.19L2027.7 1257.41L2069.92 1299.75L2127.69 1284.2L2185.35 1268.76L2216.33 1153.34L2131.78 1068.9Z"
    fill="#281758"
  />
  <path
    class="circle_4"
    d="M2075.94 860.18L2018.17 875.615L1960.51 891.164L1944.96 948.819L1929.53 1006.59L1971.75 1048.81L2014.08 1091.03L2071.74 1075.59L2129.51 1060.04L2160.38 944.733L2075.94 860.18Z"
    fill="#281758"
  />
  <path
    class="circle_4"
    d="M2125.87 1508.35L2183.53 1492.91L2241.3 1477.48L2256.73 1419.71L2272.17 1362.05L2229.95 1319.83L2187.73 1277.5L2130.07 1293.05L2072.31 1308.48L2056.87 1366.14L2041.43 1423.91L2122.7 1505.28L2125.87 1508.35Z"
    fill="#281758"
  />
  <path
    class="circle_4"
    d="M2104.42 736.015L2062.2 693.795L2019.98 651.461L1962.22 667.01L1904.56 682.445L1889.12 740.101L1873.69 797.869L1915.91 840.09L1958.13 882.31L2015.79 866.874L2073.55 851.439L2088.99 793.67L2104.42 736.015Z"
    fill="#281758"
  />
  <path
    class="circle_4"
    d="M1753.61 611.053L1695.84 626.488L1680.41 684.257L1664.97 741.912L1707.19 784.132L1749.41 826.466L1807.18 810.917L1864.84 795.482L1895.82 680.057L1811.27 595.617L1753.61 611.053Z"
    fill="#281758"
  />
  <path
    class="circle_4"
    d="M1393.95 483.711L1336.18 499.26L1278.52 514.695L1247.54 630.12L1332.09 714.56L1389.75 699.124L1447.52 683.689L1462.95 625.92L1478.39 568.265L1436.17 526.045L1393.95 483.711Z"
    fill="#281758"
  />
  <path
    class="circle_4"
    d="M595.622 1811.26L680.062 1895.82L795.487 1864.83L810.922 1807.18L826.471 1749.41L784.137 1707.19L741.917 1664.97L626.493 1695.84L611.057 1753.61L595.622 1811.26Z"
    fill="#281758"
  />
  <path
    class="circle_4"
    d="M624.109 1687.1L681.878 1671.66L739.534 1656.23L755.082 1598.46L770.518 1540.8L685.964 1456.25L628.309 1471.69L570.653 1487.23L539.669 1602.66L624.109 1687.1Z"
    fill="#281758"
  />
  <path
    class="circle_4"
    d="M1002.39 2144.94L1060.16 2129.51L1075.59 2071.74L1091.03 2014.08L1006.59 1929.53L891.163 1960.51L875.614 2018.17L860.179 2075.94L944.732 2160.38L1002.39 2144.94Z"
    fill="#281758"
  />
  <path
    class="circle_4"
    d="M651.461 2019.98L736.015 2104.42L851.439 2073.55L866.874 2015.78L882.31 1958.13L797.869 1873.69L682.445 1904.56L667.01 1962.21L651.461 2019.98Z"
    fill="#281758"
  />
  <path
    class="circle_4"
    d="M483.716 1393.95L526.049 1436.17L568.27 1478.39L625.925 1462.95L683.694 1447.52L699.129 1389.75L714.565 1332.09L672.345 1289.87L630.124 1247.54L572.355 1263.09L514.7 1278.52L499.265 1336.18L483.716 1393.95Z"
    fill="#281758"
  />
  <path
    class="circle_4"
    d="M822.044 748.842L764.275 764.277L748.84 822.046L733.404 879.701L817.845 964.255L875.614 948.706L933.269 933.271L948.704 875.615L964.253 817.847L921.919 775.626L879.699 733.406L822.044 748.842Z"
    fill="#281758"
  />
  <path
    class="circle_4"
    d="M1185.23 427.875L1127.57 443.31L1069.8 458.746L1038.93 574.17L1123.37 658.724L1181.14 643.175L1238.8 627.74L1254.23 570.084L1269.78 512.315L1227.45 470.095L1185.23 427.875Z"
    fill="#281758"
  />
  <path
    class="circle_4"
    d="M974.809 596.076L917.04 611.511L886.169 726.936L970.609 811.489L1028.38 795.941L1086.03 780.505L1101.47 722.85L1117.02 665.081L1074.68 622.861L1032.46 580.641L974.809 596.076Z"
    fill="#281758"
  />
  <path
    class="circle_4"
    d="M1153.34 2216.33L1268.76 2185.35L1299.75 2069.92L1215.19 1985.48L1099.77 2016.35L1084.33 2074.12L1068.9 2131.78L1111.12 2174.11L1153.34 2216.33Z"
    fill="#281758"
  />
  <path
    class="circle_4"
    d="M516.516 1054.36L458.747 1069.8L443.311 1127.57L427.876 1185.22L470.096 1227.44L512.316 1269.78L570.085 1254.23L627.741 1238.79L643.176 1181.14L658.725 1123.37L616.391 1081.15L574.171 1038.93L516.516 1054.36Z"
    fill="#281758"
  />
  <path
    class="circle_4"
    d="M669.28 901.599L611.511 917.035L596.076 974.804L580.641 1032.46L622.861 1074.68L665.081 1117.01L722.85 1101.46L780.505 1086.03L795.941 1028.37L811.489 970.604L769.156 928.384L726.936 886.164L669.28 901.599Z"
    fill="#281758"
  />
  <path
    class="circle_4"
    d="M2297.25 1686.2L2328.12 1570.77L2243.68 1486.22L2185.91 1501.65L2128.26 1517.2L2097.27 1632.63L2181.83 1717.07L2297.25 1686.2Z"
    fill="#281758"
  />
  <path
    class="circle_4"
    d="M1477.48 2241.3L1508.35 2125.87L1466.13 2083.65L1423.91 2041.43L1366.14 2056.87L1308.48 2072.3L1277.5 2187.73L1362.05 2272.17L1477.48 2241.3Z"
    fill="#281758"
  />
  <path
    class="circle_4"
    d="M1838.96 2144.49L1869.83 2029.06L1785.39 1944.51L1669.97 1975.49L1654.42 2033.15L1638.98 2090.92L1723.54 2175.36L1838.96 2144.49Z"
    fill="#281758"
  />
  <path
    class="circle_4"
    d="M2144.49 1838.96L2175.36 1723.54L2090.92 1638.98L1975.49 1669.97L1959.95 1727.62L1944.51 1785.39L2029.06 1869.83L2144.49 1838.96Z"
    fill="#281758"
  />
  <path
    class="circle_4"
    d="M1686.19 2297.25L1717.07 2181.83L1632.63 2097.27L1574.86 2112.71L1517.2 2128.26L1501.65 2185.91L1486.22 2243.68L1570.77 2328.12L1686.19 2297.25Z"
    fill="#281758"
  />
  <path
    class="circle_4"
    d="M1991.72 1991.72L2007.16 1933.95L2022.59 1876.3L1938.15 1791.74L1822.73 1822.73L1807.18 1880.38L1791.75 1938.15L1833.97 1980.37L1876.3 2022.59L1991.72 1991.72Z"
    fill="#281758"
  />
  <path
    class="circle_5"
    d="M417.322 1407L474.977 1391.56L505.961 1276.14L421.407 1191.7L363.752 1207.13L305.983 1222.57L275.112 1337.99L359.553 1422.54L417.322 1407Z"
    fill="#1D1040"
  />
  <path
    class="circle_5"
    d="M642.723 2017.6L658.158 1959.94L673.707 1902.17L589.153 1817.73L473.729 1848.61L442.858 1964.03L527.299 2048.58L642.723 2017.6Z"
    fill="#1D1040"
  />
  <path
    class="circle_5"
    d="M698.675 2226.32L714.111 2168.55L729.546 2110.89L645.106 2026.45L587.337 2041.89L529.681 2057.32L498.697 2172.75L583.251 2257.19L698.675 2226.32Z"
    fill="#1D1040"
  />
  <path
    class="circle_5"
    d="M250.03 1013.96L219.159 1129.39L303.599 1213.83L361.368 1198.39L419.024 1182.84L450.008 1067.53L365.454 982.977L250.03 1013.96Z"
    fill="#1D1040"
  />
  <path
    class="circle_5"
    d="M473.161 1615.71L530.93 1600.28L561.801 1484.85L519.581 1442.63L477.361 1400.3L419.592 1415.85L361.936 1431.28L330.952 1546.71L415.506 1631.15L473.161 1615.71Z"
    fill="#1D1040"
  />
  <path
    class="circle_5"
    d="M586.77 1808.99L602.319 1751.22L617.754 1693.57L533.2 1609.02L475.545 1624.45L417.889 1640L386.905 1755.42L471.346 1839.86L586.77 1808.99Z"
    fill="#1D1040"
  />
  <path
    class="circle_5"
    d="M907.28 2282.27L922.829 2224.5L938.264 2166.84L853.824 2082.29L796.055 2097.72L738.399 2113.27L722.85 2170.93L707.415 2228.7L791.969 2313.14L907.28 2282.27Z"
    fill="#1D1040"
  />
  <path
    class="circle_5"
    d="M1067.53 450.005L1125.19 434.57L1182.85 419.021L1198.39 361.365L1213.83 303.597L1129.39 219.156L1013.96 250.027L982.98 365.451L1067.53 450.005Z"
    fill="#1D1040"
  />
  <path
    class="circle_5"
    d="M1688.47 2305.99L1630.81 2321.43L1573.04 2336.86L1557.61 2394.63L1542.17 2452.29L1626.61 2536.84L1742.04 2505.86L1773.02 2390.43L1688.47 2305.99Z"
    fill="#1D1040"
  />
  <path
    class="circle_5"
    d="M555.672 708.437L524.688 823.748L609.128 908.302L666.897 892.867L724.552 877.318L740.101 819.662L755.536 761.893L713.316 719.673L670.983 677.453L555.672 708.437Z"
    fill="#1D1040"
  />
  <path
    class="circle_5"
    d="M402.794 861.195L371.924 976.506L414.144 1018.84L456.364 1061.06L514.133 1045.62L571.789 1030.08L587.337 972.42L602.773 914.765L560.553 872.431L518.219 830.211L402.794 861.195Z"
    fill="#1D1040"
  />
  <path
    class="circle_5"
    d="M708.436 555.672L677.452 670.983L719.672 713.316L761.892 755.536L819.661 740.101L877.317 724.552L892.866 666.897L908.301 609.128L866.081 566.908L823.747 524.688L708.436 555.672Z"
    fill="#1D1040"
  />
  <path
    class="circle_5"
    d="M1640 417.769L1624.45 475.538L1609.02 533.194L1651.24 575.527L1693.57 617.747L1751.23 602.312L1809 586.763L1839.87 471.339L1755.43 386.898L1640 417.769Z"
    fill="#1D1040"
  />
  <path
    class="circle_5"
    d="M1431.29 361.929L1400.3 477.354L1484.86 561.794L1542.51 546.359L1600.28 530.924L1631.15 415.499L1546.71 330.945L1431.29 361.929Z"
    fill="#1D1040"
  />
  <path
    class="circle_5"
    d="M1222.57 305.98L1191.7 421.404L1233.92 463.625L1276.14 505.958L1333.91 490.409L1391.56 474.974L1407 417.319L1422.55 359.55L1337.99 275.109L1222.57 305.98Z"
    fill="#1D1040"
  />
  <path
    class="circle_5"
    d="M1894.8 2353.09L1925.78 2237.67L1841.23 2153.23L1783.58 2168.66L1725.81 2184.1L1710.37 2241.87L1694.94 2299.52L1779.38 2384.08L1894.8 2353.09Z"
    fill="#1D1040"
  />
  <path
    class="circle_5"
    d="M2200.33 2047.56L2231.31 1932.14L2146.76 1847.7L2031.33 1878.57L2015.9 1936.33L2000.46 1993.99L2084.9 2078.54L2200.33 2047.56Z"
    fill="#1D1040"
  />
  <path
    class="circle_5"
    d="M2047.56 2200.33L2078.55 2084.9L1993.99 2000.46L1936.34 2015.9L1878.57 2031.33L1863.13 2089.1L1847.7 2146.76L1932.14 2231.31L2047.56 2200.33Z"
    fill="#1D1040"
  />
  <path
    class="circle_5"
    d="M2505.86 1742.04L2536.84 1626.61L2452.29 1542.17L2394.63 1557.61L2336.86 1573.04L2305.99 1688.47L2390.43 1773.02L2505.86 1742.04Z"
    fill="#1D1040"
  />
  <path
    class="circle_5"
    d="M2353.09 1894.79L2384.08 1779.37L2299.52 1694.93L2184.1 1725.8L2153.23 1841.22L2237.67 1925.78L2353.09 1894.79Z"
    fill="#1D1040"
  />
  <path
    class="circle_5"
    d="M1848.61 473.722L1833.17 531.491L1817.74 589.147L1859.96 631.367L1902.18 673.7L1959.95 658.152L2017.6 642.716L2033.15 585.061L2048.59 527.292L1964.03 442.852L1848.61 473.722Z"
    fill="#1D1040"
  />
  <path
    class="circle_5"
    d="M1116 2338.11L1146.98 2222.68L1062.43 2138.24L1004.77 2153.68L947.003 2169.11L916.133 2284.54L1000.57 2369.09L1116 2338.11Z"
    fill="#1D1040"
  />
  <path
    class="circle_5"
    d="M1324.71 2394.06L1355.59 2278.64L1271.14 2194.2L1213.38 2209.63L1155.72 2225.07L1124.74 2340.49L1209.29 2424.93L1324.71 2394.06Z"
    fill="#1D1040"
  />
  <path
    class="circle_5"
    d="M1533.43 2450.01L1564.3 2334.59L1479.86 2250.04L1422.09 2265.47L1364.44 2281.02L1333.45 2396.44L1418.01 2480.88L1533.43 2450.01Z"
    fill="#1D1040"
  />
  <path
    class="circle_5"
    d="M861.201 402.792L830.217 518.217L914.771 602.771L972.426 587.335L1030.08 571.787L1045.63 514.131L1061.07 456.362L1018.85 414.142L976.625 371.922L861.201 402.792Z"
    fill="#1D1040"
  />
  <path
    class="circle_5"
    d="M2057.32 529.679L2041.89 587.335L2026.45 645.104L2068.67 687.324L2110.89 729.544L2168.55 714.109L2226.32 698.674L2257.19 583.249L2172.75 498.695L2057.32 529.679Z"
    fill="#1D1040"
  />
  <path
    class="circle_5"
    d="M2334.59 1564.3L2392.25 1548.87L2450.02 1533.43L2480.89 1418.01L2396.45 1333.45L2338.68 1348.89L2281.02 1364.44L2265.48 1422.09L2250.04 1479.86L2331.42 1561.12L2334.59 1564.3Z"
    fill="#1D1040"
  />
  <path
    class="circle_5"
    d="M2282.84 1140.28L2225.07 1155.72L2194.2 1271.14L2278.64 1355.58L2336.41 1340.15L2394.06 1324.71L2424.94 1209.29L2340.5 1124.73L2282.84 1140.28Z"
    fill="#1D1040"
  />
  <path
    class="circle_5"
    d="M2170.93 722.849L2113.28 738.398L2097.73 796.054L2082.29 853.823L2124.51 896.043L2166.85 938.263L2224.5 922.828L2282.27 907.279L2313.14 791.968L2228.7 707.414L2170.93 722.849Z"
    fill="#1D1040"
  />
  <path
    class="circle_5"
    d="M2226.89 931.568L2169.12 947.003L2153.68 1004.77L2138.25 1062.43L2180.47 1104.65L2222.69 1146.98L2280.46 1131.43L2338.11 1116L2369.09 1000.57L2284.54 916.133L2226.89 931.568Z"
    fill="#1D1040"
  />
  <path
    class="circle_6"
    d="M2714.57 1797.99L2745.56 1682.57L2661 1598.02L2603.35 1613.56L2545.58 1629L2514.71 1744.42L2599.15 1828.86L2714.57 1797.99Z"
    fill="#6347F5"
  />
  <path
    class="circle_6"
    d="M2266.04 585.64L2250.49 643.296L2235.06 700.951L2277.28 743.285L2319.61 785.505L2377.27 770.07L2435.04 754.521L2465.91 639.097L2381.47 554.656L2266.04 585.64Z"
    fill="#100926"
  />
  <path
    class="circle_6"
    d="M2543.2 1620.26L2600.97 1604.71L2658.62 1589.27L2689.61 1473.85L2605.05 1389.41L2547.4 1404.84L2489.63 1420.28L2474.19 1478.05L2458.76 1535.7L2540.13 1617.08L2543.2 1620.26Z"
    fill="#100926"
  />
  <path
    class="circle_6"
    d="M2491.44 1196.13L2433.79 1211.68L2402.8 1327.1L2487.36 1411.54L2545.01 1396.11L2602.78 1380.67L2633.65 1265.25L2549.21 1180.7L2491.44 1196.13Z"
    fill="#100926"
  />
  <path
    class="circle_6"
    d="M2379.65 778.81L2321.88 794.246L2306.45 852.015L2291.01 909.67L2333.23 951.89L2375.45 994.224L2433.22 978.675L2490.87 963.24L2521.86 847.815L2437.3 763.375L2379.65 778.81Z"
    fill="#100926"
  />
  <path
    class="circle_6"
    d="M2435.6 987.525L2377.84 1002.96L2362.4 1060.62L2346.96 1118.39L2389.19 1160.61L2431.41 1202.83L2489.17 1187.39L2546.83 1171.95L2577.7 1056.53L2493.26 971.977L2435.6 987.525Z"
    fill="#100926"
  />
  <path
    class="circle_6"
    d="M324.596 1553.07L266.827 1568.62L209.172 1584.05L193.736 1641.71L178.188 1699.48L220.521 1741.7L262.741 1783.92L320.397 1768.48L378.166 1753.05L409.036 1637.62L324.596 1553.07Z"
    fill="#100926"
  />
  <path
    class="circle_6"
    d="M268.643 1344.47L210.987 1359.9L153.218 1375.34L137.783 1433.11L122.348 1490.76L164.568 1532.98L206.788 1575.32L264.557 1559.77L322.212 1544.33L353.197 1428.91L268.643 1344.47Z"
    fill="#100926"
  />
  <path
    class="circle_6"
    d="M318.58 1992.64L376.349 1977.2L434.004 1961.77L449.553 1904L464.989 1846.34L422.768 1804.01L380.435 1761.79L322.779 1777.22L265.01 1792.77L249.575 1850.43L234.14 1908.2L315.516 1989.46L318.58 1992.64Z"
    fill="#100926"
  />
  <path
    class="circle_6"
    d="M297.243 1220.3L255.023 1177.97L212.69 1135.75L155.034 1151.19L97.2652 1166.73L81.8299 1224.39L66.3945 1282.16L108.615 1324.38L150.835 1366.6L208.604 1351.16L266.259 1335.61L281.808 1277.96L297.243 1220.3Z"
    fill="#100926"
  />
  <path
    class="circle_6"
    d="M489.958 2170.37L520.942 2054.94L436.388 1970.5L378.732 1985.94L320.963 2001.37L290.093 2116.8L374.533 2201.35L489.958 2170.37Z"
    fill="#100926"
  />
  <path
    class="circle_6"
    d="M545.911 2379.09L576.781 2263.66L492.341 2179.22L376.917 2210.09L345.933 2325.52L430.486 2409.96L545.911 2379.09Z"
    fill="#100926"
  />
  <path
    class="circle_6"
    d="M963.232 2490.88L978.667 2433.22L994.216 2375.46L909.662 2291.02L852.007 2306.45L794.238 2321.89L763.367 2437.31L847.807 2521.86L963.232 2490.88Z"
    fill="#100926"
  />
  <path
    class="circle_6"
    d="M1380.67 2602.79L1411.54 2487.36L1327.1 2402.81L1269.33 2418.24L1211.67 2433.79L1180.69 2549.22L1265.24 2633.66L1380.67 2602.79Z"
    fill="#100926"
  />
  <path
    class="circle_6"
    d="M1171.95 2546.83L1187.39 2489.18L1202.82 2431.41L1118.38 2346.97L1060.61 2362.4L1002.96 2377.84L987.52 2435.61L971.972 2493.26L1056.53 2577.7L1171.95 2546.83Z"
    fill="#100926"
  />
  <path
    class="circle_6"
    d="M1589.27 2658.63L1620.26 2543.2L1535.7 2458.76L1478.05 2474.2L1420.28 2489.63L1389.41 2605.06L1473.85 2689.61L1589.27 2658.63Z"
    fill="#100926"
  />
  <path
    class="circle_6"
    d="M1797.99 2714.58L1828.86 2599.16L1744.42 2514.71L1686.65 2530.15L1628.99 2545.59L1598.12 2661.01L1682.56 2745.56L1797.99 2714.58Z"
    fill="#100926"
  />
  <path
    class="circle_6"
    d="M754.515 2435.04L785.499 2319.62L700.945 2235.06L643.29 2250.5L585.635 2266.05L554.65 2381.47L639.204 2465.91L754.515 2435.04Z"
    fill="#100926"
  />
  <path
    class="circle_6"
    d="M2714.57 1797.99L2745.56 1682.57L2661 1598.02L2603.35 1613.56L2545.58 1629L2514.71 1744.42L2599.15 1828.86L2714.57 1797.99Z"
    fill="#100926"
  />
  <path
    class="circle_6"
    d="M2256.28 2256.29L2287.15 2140.86L2202.71 2056.42L2087.29 2087.29L2071.85 2145.06L2056.42 2202.72L2140.86 2287.16L2256.28 2256.29Z"
    fill="#100926"
  />
  <path
    class="circle_6"
    d="M2561.81 1950.76L2592.79 1835.33L2508.24 1750.89L2392.82 1781.76L2377.38 1839.42L2361.95 1897.19L2446.39 1981.63L2561.81 1950.76Z"
    fill="#100926"
  />
  <path
    class="circle_6"
    d="M2103.52 2409.05L2134.39 2293.62L2049.95 2209.18L1992.29 2224.62L1934.52 2240.05L1919.09 2297.82L1903.65 2355.48L1988.09 2439.92L2103.52 2409.05Z"
    fill="#100926"
  />
  <path
    class="circle_6"
    d="M2409.05 2103.52L2424.48 2045.75L2439.92 1988.1L2355.48 1903.66L2240.05 1934.53L2224.62 1992.18L2209.18 2049.95L2251.4 2092.17L2293.62 2134.39L2409.05 2103.52Z"
    fill="#100926"
  />
  <path
    class="circle_6"
    d="M1950.75 2561.81L1981.62 2446.39L1897.18 2361.95L1839.41 2377.38L1781.76 2392.82L1750.89 2508.24L1835.33 2592.68L1950.75 2561.81Z"
    fill="#100926"
  />
  <path
    class="circle_6"
    d="M1792.77 265.011L1777.22 322.78L1761.78 380.436L1846.34 464.989L1903.99 449.554L1961.76 434.005L1992.63 318.581L1908.19 234.141L1792.77 265.011Z"
    fill="#100926"
  />
  <path
    class="circle_6"
    d="M1375.33 153.222L1344.46 268.647L1428.9 353.2L1486.67 337.652L1544.33 322.216L1575.31 206.792L1490.76 122.352L1375.33 153.222Z"
    fill="#100926"
  />
  <path
    class="circle_6"
    d="M1584.05 209.172L1568.61 266.827L1553.07 324.596L1637.62 409.036L1695.27 393.601L1753.04 378.166L1768.48 320.397L1783.91 262.741L1699.47 178.188L1584.05 209.172Z"
    fill="#100926"
  />
  <path
    class="circle_6"
    d="M1166.73 97.2691L1135.75 212.694L1220.3 297.247L1277.95 281.812L1335.61 266.263L1366.59 150.839L1282.15 66.3984L1166.73 97.2691Z"
    fill="#100926"
  />
  <path
    class="circle_6"
    d="M958.011 41.4295L927.027 156.854L1011.58 241.294L1069.24 225.859L1127.01 210.424L1157.88 94.9991L1073.44 10.4453L958.011 41.4295Z"
    fill="#100926"
  />
  <path
    class="circle_6"
    d="M2001.37 320.964L1970.5 436.389L2054.94 520.943L2112.71 505.394L2170.37 489.958L2201.35 374.534L2116.8 290.094L2001.37 320.964Z"
    fill="#100926"
  />
  <path
    class="circle_6"
    d="M41.4255 958.015L10.4414 1073.44L94.9952 1157.88L152.651 1142.44L210.42 1127.01L241.29 1011.59L156.85 927.031L41.4255 958.015Z"
    fill="#100926"
  />
  <path
    class="circle_6"
    d="M499.718 499.718L468.734 615.143L553.288 699.583L668.713 668.713L684.148 610.944L699.583 553.288L615.143 468.734L499.718 499.718Z"
    fill="#100926"
  />
  <path
    class="circle_6"
    d="M194.19 805.25L163.206 920.674L247.76 1005.11L363.184 974.244L378.62 916.475L394.055 858.819L309.615 774.266L194.19 805.25Z"
    fill="#100926"
  />
  <path
    class="circle_6"
    d="M652.483 346.961L621.499 462.385L706.053 546.825L763.708 531.39L821.477 515.955L836.913 458.186L852.348 400.53L767.908 315.977L652.483 346.961Z"
    fill="#100926"
  />
  <path
    class="circle_6"
    d="M346.954 652.484L331.519 710.14L315.97 767.909L400.524 852.349L515.948 821.478L531.383 763.709L546.819 706.054L504.598 663.834L462.378 621.5L346.954 652.484Z"
    fill="#100926"
  />
  <path
    d="M805.247 194.195L774.263 309.619L858.816 394.06L916.472 378.624L974.241 363.189L1005.11 247.765L920.671 163.211L805.247 194.195Z"
    fill="#100926"
  />
  <path
    d="M2210.09 376.922L2194.65 434.577L2179.22 492.346L2221.44 534.566L2263.66 576.786L2321.31 561.351L2379.08 545.916L2409.95 430.491L2325.51 345.938L2210.09 376.922Z"
    fill="#100926"
  />
  <path
    d="M1380.67 1453.76V1374.43H1301.45L1343.1 1332.77H1422.43V1411.99L1380.67 1453.76Z"
    fill="white"
  />
</template>
<style lang="scss" scoped>
@keyframes center {
  5% {
    fill: #ffffa2;
  }
  15% {
    fill: #7642ff;
  }
}

@keyframes circle1 {
  5% {
    fill: #ffd37b;
  }
  15% {
    fill: #5e35cc;
  }
}

@keyframes circle2 {
  5% {
    fill: #ff5b00;
  }
  15% {
    fill: #4c2ba7;
  }
}

@keyframes circle3 {
  5% {
    fill: #ab5dff;
  }
  15% {
    fill: #3b2280;
  }
}

@keyframes circle4 {
  5% {
    fill: #6347f5;
  }
  15% {
    fill: #281758;
  }
}

@keyframes circle5 {
  5% {
    fill: #4b28aa;
  }
  15% {
    fill: #1d1040;
  }
}

@keyframes circle6 {
  5% {
    fill: #301a66;
  }
  15% {
    fill: #100926;
  }
}

path {
  animation: 3s linear infinite none;
}

.center {
  animation-name: center;
  animation-delay: 1s;
}

.circle_1 {
  animation-name: circle1;
  animation-delay: 1.15s;
}

.circle_2 {
  animation-name: circle2;
  animation-delay: 1.3s;
}

.circle_3 {
  animation-name: circle3;
  animation-delay: 1.45s;
}

.circle_4 {
  animation-name: circle4;
  animation-delay: 1.6s;
}

.circle_5 {
  animation-name: circle5;
  animation-delay: 1.75s;
}

.circle_6 {
  animation-name: circle6;
  animation-delay: 1.9s;
}
</style>
