<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
    <g class="cube">
      <polygon
        class="cls-1"
        points="148.0078023865 190.9140298871 148.0078023865 309.2479988172 250.4880256107 368.4149832823 250.4880256107 250.0810143522 148.0078023865 190.9140298871"
        fill="#44299e"
      />
      <polygon
        class="cls-2"
        points="352.9682222651 190.9140298894 352.9682222598 309.2479988195 250.4879990329 368.41498328 250.4879990382 250.0810143499 352.9682222651 190.9140298894"
        fill="#6347f5"
      />
      <polygon
        class="cls-2"
        points="148.0078023865 190.9140298871 250.4880256107 131.747045422 352.9682488349 190.9140298871 250.4880256107 250.0810143522 148.0078023865 190.9140298871"
        fill="#6347f5"
      />
    </g>
    <g class="angles">
      <polygon
        class="cls-1"
        points="83.37522556 157.2577766519 54.3404287496 157.2577766519 54.3404287496 53.8603001688 157.7379052328 53.8603001688 157.7379052328 82.8950969792 83.37522556 82.8950969792 83.37522556 157.2577766519"
        fill="#44299e"
      />
      <polygon
        class="cls-1"
        points="446.6327157816 157.2577766519 417.5979189712 157.2577766519 417.5979189712 82.8950969792 343.2387835852 82.8950969792 343.2387835852 53.8603001688 446.6327157816 53.8603001688 446.6327157816 157.2577766519"
        fill="#44299e"
      />
      <polygon
        class="cls-1"
        points="446.6327157816 446.2979029562 343.2387835852 446.2979029562 343.2387835852 417.2631061458 417.5979189712 417.2631061458 417.5979189712 342.9039707598 446.6327157816 342.9039707598 446.6327157816 446.2979029562"
        fill="#44299e"
      />
      <polygon
        class="cls-1"
        points="157.7379052328 446.2979029562 54.3404287496 446.2979029562 54.3404287496 342.9039707598 83.37522556 342.9039707598 83.37522556 417.2631061458 157.7379052328 417.2631061458 157.7379052328 446.2979029562"
        fill="#44299e"
      />
    </g>
  </svg>
</template>

<style lang="scss" scoped>
@keyframes anglesAnimate {
  0% {
    transform: rotate(0turn);
  }
  70% {
    transform: rotate(45deg) scale(0.9);
  }
  100% {
    transform: rotate(0turn);
  }
}

@keyframes cubeAnimate {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

.angles {
  transform-origin: center center;
  animation-name: anglesAnimate;
  animation-duration: 0.75s;
  animation-iteration-count: 1;
}

.cube {
  transform-origin: center center;
  animation-name: cubeAnimate;
  animation-duration: 0.75s;
  animation-iteration-count: 1;
}
</style>
