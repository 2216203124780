
import { defineComponent } from 'vue'
import HeroIcon from '../Icons/HeroIcon.vue'
import IconBase from '../Icons/IconBase.vue'

export default defineComponent({
  name: 'BlockHero',
  components: {
    HeroIcon,
    IconBase,
  },
})
