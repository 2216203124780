<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_7783_146606)">
      <path
        d="M12.7198 16.9066L10.6548 18.9156C10.2968 19.2636 9.69776 19.1096 9.55276 18.6316L8.17676 14.1016"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.0291 10.9275C15.0291 10.9275 12.4401 13.2635 11.1121 14.4625C10.7151 14.8205 10.7481 15.4505 11.1781 15.7675L16.5561 19.7405C17.0851 20.1315 17.8411 19.8445 17.9781 19.2005L20.6191 6.75348C20.7471 6.15148 20.1561 5.64848 19.5821 5.86948L4.06712 11.8535C3.61012 12.0295 3.63212 12.6825 4.09912 12.8285L8.17612 14.1005"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7783_146606">
        <rect
          width="24"
          height="24"
          fill="currentColor"
          transform="translate(0.924805)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
