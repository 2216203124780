
import { defineComponent } from 'vue'
import Slider from '@/types/slider'

import { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import AnimatedIcons from '../Icons/Animated/AnimatedIcons.vue'

interface Data {
  isMobile: boolean
  navigationModule?: typeof Navigation
}

export default defineComponent({
  name: 'BlockSlider',
  components: {
    Swiper,
    SwiperSlide,
    AnimatedIcons,
  },
  props: {
    title: {
      type: String,
      default: 'Slider title',
    },
    sliderData: {
      type: Array as () => Slider[],
      required: true,
    },
  },
  data(): Data {
    return {
      isMobile: false,
      navigationModule: Navigation,
    }
  },
  mounted() {
    console.log(this.$refs.slider)
    this.chechMobile()
    window.addEventListener('resize', this.chechMobile)
  },
  unmounted() {
    window.removeEventListener('resize', this.chechMobile)
  },
  methods: {
    chechMobile() {
      this.isMobile = window.innerWidth <= 768
    },
  },
})
