
import { defineComponent } from 'vue'
import LayersIcon from './LayersIcon.vue'
import FeesIcon from './FeesIcon.vue'
import BlockchainIcon from './BlockchainIcon.vue'
import CrosschainIcon from './CrosschainIcon.vue'
import RocketIcon from './RocketIcon.vue'
import JoystickIcon from './JoystickIcon.vue'

export default defineComponent({
  name: 'AnimatedIcons',
  components: {
    LayersIcon,
    FeesIcon,
    BlockchainIcon,
    CrosschainIcon,
    RocketIcon,
    JoystickIcon,
  },
  props: {
    imageName: {
      type: String,
      default: '',
    },
  },
})
