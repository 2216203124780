
import { defineComponent } from 'vue'
import BlockLocales from '@/components/Blocks/BlockLocales.vue'

export default defineComponent({
  name: 'HeaderBlock',
  components: {
    BlockLocales,
  },
})
