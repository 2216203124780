<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
    <g class="cube cube_1">
      <polygon
        class="cls-1"
        points="65.8714873588 95.5590129273 65.8714873588 177.9961812825 137.2641693705 219.2147654601 137.2641693705 136.7775971049 65.8714873588 95.5590129273"
        fill="#44299e"
      />
      <polygon
        class="cls-1"
        points="208.6568328724 95.5590129289 208.6568328687 177.9961812841 137.2641508551 219.2147654585 137.2641508588 136.7775971033 208.6568328724 95.5590129289"
        fill="#44299e"
      />
      <polygon
        class="cls-2"
        points="65.8714873588 95.5590129273 137.2641693705 54.3404287496 208.6568513822 95.5590129273 137.2641693705 136.7775971049 65.8714873588 95.5590129273"
        fill="#6347f5"
      />
    </g>
    <g class="cube cube_2">
      <polygon
        class="cls-1"
        points="292.316293149 95.5590129273 292.316293149 177.9961812825 363.7089751608 219.2147654601 363.7089751608 136.7775971049 292.316293149 95.5590129273"
        fill="#44299e"
      />
      <polygon
        class="cls-1"
        points="435.1016386626 95.5590129289 435.1016386589 177.9961812841 363.7089566454 219.2147654585 363.7089566491 136.7775971033 435.1016386626 95.5590129289"
        fill="#44299e"
      />
      <polygon
        class="cls-2"
        points="292.316293149 95.5590129273 363.7089751608 54.3404287496 435.1016571724 95.5590129273 363.7089751608 136.7775971049 292.316293149 95.5590129273"
        fill="#6347f5"
      />
    </g>
    <g class="cube cube_3">
      <polygon
        class="cls-1"
        points="65.8714873588 322.0038187175 65.8714873588 404.4409870727 137.2641693705 445.6595712504 137.2641693705 363.2224028951 65.8714873588 322.0038187175"
        fill="#44299e"
      />
      <polygon
        class="cls-1"
        points="208.6568328724 322.0038187191 208.6568328687 404.4409870743 137.2641508551 445.6595712488 137.2641508588 363.2224028935 208.6568328724 322.0038187191"
        fill="#44299e"
      />
      <polygon
        class="cls-2"
        points="65.8714873588 322.0038187175 137.2641693705 280.7852345399 208.6568513822 322.0038187175 137.2641693705 363.2224028951 65.8714873588 322.0038187175"
        fill="#6347f5"
      />
    </g>
    <g class="cube cube_4">
      <polygon
        class="cls-1"
        points="292.316293149 322.0038187175 292.316293149 404.4409870727 363.7089751608 445.6595712504 363.7089751608 363.2224028951 292.316293149 322.0038187175"
        fill="#44299e"
      />
      <polygon
        class="cls-1"
        points="435.1016386626 322.0038187191 435.1016386589 404.4409870743 363.7089566454 445.6595712488 363.7089566491 363.2224028935 435.1016386626 322.0038187191"
        fill="#44299e"
      />
      <polygon
        class="cls-2"
        points="292.316293149 322.0038187175 363.7089751608 280.7852345399 435.1016571724 322.0038187175 363.7089751608 363.2224028951 292.316293149 322.0038187175"
        fill="#6347f5"
      />
    </g>
    <g>
      <rect
        class="cls-2"
        x="349.7861029539"
        y="248.8121942962"
        width="27.8402964105"
        height="73.1895292355"
        fill="#6347f5"
      />
      <rect
        class="cls-1"
        x="178.3185121663"
        y="349.3050611003"
        width="143.2728535249"
        height="27.8402964105"
        fill="#44299e"
      />
      <rect
        class="cls-1"
        x="175.7118828515"
        y="122.8579626595"
        width="148.4861121545"
        height="27.8402964105"
        fill="#44299e"
      />
      <rect
        class="cls-1"
        x="123.3458014605"
        y="136.7781108648"
        width="27.8402964105"
        height="112.0340834314"
        fill="#44299e"
      />
    </g>
  </svg>
</template>
<style lang="scss" scoped>
@keyframes cubeAnimate {
  0% {
    transform: scale(1);
  }
  65% {
    transform: scale(1.4) translate(30px, 30px);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes cubeAnimate2 {
  0% {
    transform: scale(1);
  }
  65% {
    transform: scale(1.4) translate(-30px, 30px);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes cubeAnimate3 {
  0% {
    transform: scale(1);
  }
  65% {
    transform: scale(1.4) translate(30px, -30px);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes cubeAnimate4 {
  0% {
    transform: scale(1);
  }
  65% {
    transform: scale(1.4) translate(-30px, -30px);
  }
  100% {
    transform: scale(1);
  }
}
.cube {
  transform-origin: center center;
  animation-name: cubeAnimate;
  animation-duration: 0.4s;
  animation-iteration-count: 1;

  &_2 {
    animation-name: cubeAnimate2;
    animation-delay: 0.2s;
  }

  &_3 {
    animation-name: cubeAnimate3;
    animation-delay: 0.4s;
  }

  &_4 {
    animation-name: cubeAnimate4;
    animation-delay: 0.6s;
  }
}
</style>
