<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
    <g class="rotate">
      <polygon
        points="263.4467619063 198.2139777786 237.5256136696 198.2139777786 237.5256136696 88.7157543456 338.6598592677 88.7157543456 338.6598592677 114.6369025822 263.4467619063 114.6369025822 263.4467619063 198.2139777786"
        fill="#44299e"
      />
    </g>
    <g class="rotate">
      <polygon
        points="393.4424910681 170.5487592088 250.4853967373 170.5487592088 107.5283024065 170.5487592088 54.3404287496 374.2462834339 119.1887847546 411.6867114747 188.6228614727 352.4860617026 250.4853967373 352.4860617026 312.3475364766 352.4860617026 381.7816131946 411.6867114747 446.6299691996 374.2462834339 393.4424910681 170.5487592088"
        fill="#6347f5"
      />
    </g>
    <g class="circle">
      <circle
        cx="338.6619361453"
        cy="259.0025657851"
        r="38.6173403385"
        fill="#13173e"
      />
    </g>
    <g class="cross">
      <polygon
        points="209.4967109323 242.8796634673
      179.2963741158 242.8796634673 179.2963741158 212.6793266507 147.0507758013
      212.6793266507 147.0507758013 242.8796634673 116.8504389847 242.8796634673
      116.8504389847 275.1252617818 147.0503802759 275.1252617818 147.0503802759
      305.3255985984 179.2963741158 305.3255985984 179.2963741158 275.1252617818
      209.4967109323 275.1252617818 209.4967109323 242.8796634673"
        fill="#13173e"
      />
    </g>
  </svg>
</template>

<style lang="scss" scoped>
@keyframes rotateAnimate {
  0% {
    transform: rotate(0);
  }
  60% {
    transform: rotate(-10deg);
  }
  80% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0);
  }
}

@keyframes circleAnimate {
  0% {
    transform: scale(1) translate(0);
  }
  70% {
    transform: translate(80px, 0px) scale(0.1);
  }
  100% {
    transform: scale(1) translate();
  }
}

@keyframes crossAnimate {
  0% {
    transform: scale(1) translate(0);
  }
  35% {
    transform: rotate(45deg) translate(-0px, 60px) scale(0.5);
  }
  72% {
    transform: rotate(90deg) translate(30px, 80px) scale(0.1);
  }
  73% {
    transform: translate(-80px, 10px) scale(0.1) rotate(0deg);
  }
  100% {
    transform: scale(1) translate();
  }
}

.rotate {
  transform-origin: center center;
  animation-name: rotateAnimate;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
}

.circle {
  transform-origin: center center;
  animation-name: circleAnimate;
  animation-duration: 0.45s;
  animation-iteration-count: 1;
  animation-delay: 0.1s;
}

.cross {
  transform-origin: center center;
  animation-name: crossAnimate;
  animation-duration: 0.45s;
  animation-iteration-count: 1;
}
</style>
