<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.1765 18.4742C17.6835 19.9159 15.7382 20.7973 13.6699 20.9691C11.6015 21.1409 9.53742 20.5926 7.82702 19.417C6.11662 18.2415 4.86508 16.5109 4.28433 14.5184C3.70359 12.5258 3.82933 10.3938 4.64028 8.48339C5.45122 6.57295 6.89752 5.00149 8.73425 4.03513C10.571 3.06878 12.6853 2.76692 14.719 3.18067C16.7528 3.59443 18.5811 4.69837 19.8942 6.30555C21.2074 7.91273 21.9247 9.92437 21.9248 11.9998V11.9998C21.8486 15.5899 18.0115 16.1919 17.6339 12.693L17.0721 8.98823L12.3869 15.191L11.8251 8.50974L5.56246 17.1752"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
