<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
    <g class="icon">
      <g>
        <polygon
          points="306.7952904206 53.9331079749 143.9125956128 216.815323331 283.7504434525 356.6531711707 446.6331382603 193.7709558146 446.6331382603 53.9331079749 306.7952904206 53.9331079749"
          fill="#6347f5"
        />
      </g>
      <g>
        <circle
          cx="332.5768225694"
          cy="167.9892300881"
          r="39.8753638748"
          fill="#13173e"
        />
      </g>
      <g>
        <polygon
          points="204.580362166 156.1478753673 101.1469249727 259.5813125607 54.3404287496 212.7748163377 116.6090998825 150.5061452048 204.580362166 156.1478753673"
          fill="#44299e"
        />
        <polygon
          points="344.4181780777 295.9856912769 240.9847408797 399.4191284656 287.7912371006 446.2256246907 350.0599082363 383.9569535606 344.4181780777 295.9856912769"
          fill="#44299e"
        />
      </g>
      <g>
        <rect
          class="trail1"
          x="72.2595671462"
          y="312.3961815275"
          width="80.7011690166"
          height="31.4179916438"
          transform="translate(-199.022646057 175.7271833685) rotate(-45)"
          fill="#6347f5"
        />
        <rect
          class="trail2"
          x="132.1104843775"
          y="372.2470987588"
          width="80.7011690166"
          height="31.4179916438"
          transform="translate(-223.8137076947 235.5781005999) rotate(-45)"
          fill="#6347f5"
        />
      </g>
    </g>
  </svg>
</template>

<style lang="scss" scoped>
@keyframes iconAnimate {
  0% {
    transform: translate(0, 0);
  }
  33% {
    transform: translate(-20px, -50px);
  }
  66% {
    transform: translate(50px, 20px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes trail1Animate {
  0% {
    width: 80px;
    transform: translate(-199.023px, 175.727px) rotate(-45deg);
  }
  50% {
    width: 0px;
    transform: translate(-144.023px, 120.727px) rotate(-45deg);
  }
  100% {
    width: 80px;
    transform: translate(-199.023px, 175.727px) rotate(-45deg);
  }
}

@keyframes trail2Animate {
  0% {
    width: 80px;
    transform: translate(-223.814px, 235.578px) rotate(-45deg);
  }
  50% {
    width: 0px;
    transform: translate(-168.814px, 180.578px) rotate(-45deg);
  }
  100% {
    width: 80px;
    transform: translate(-223.814px, 235.578px) rotate(-45deg);
  }
}

.icon {
  animation-name: iconAnimate;
  animation-duration: 1.2s;
  animation-iteration-count: 1;
}

.trail1 {
  position: relative;
  animation-name: trail1Animate;
  animation-duration: 0.5s;
  animation-iteration-count: 2;
}

.trail2 {
  position: relative;
  animation-name: trail2Animate;
  animation-duration: 0.5s;
  animation-iteration-count: 2;
}
</style>
