
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'IconBase',
  props: {
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
  },
})
