
import { defineComponent } from 'vue'
import MosaicItem from '@/types/mosaic'

export default defineComponent({
  name: 'BlockMosaic',
  props: {
    mosaicData: {
      type: Array as () => MosaicItem[],
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isMobile: false,
    }
  },
  mounted() {
    this.checkMobile()
    window.addEventListener('resize', this.checkMobile)
  },
  unmounted() {
    window.removeEventListener('resize', this.checkMobile)
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 768
    },
  },
})
