
import { defineComponent } from 'vue'
import HeaderBlock from '@/components/HeaderBlock.vue'
import FooterBlock from '@/components/FooterBlock.vue'

export default defineComponent({
  name: 'App',
  components: {
    HeaderBlock,
    FooterBlock,
  },
})
