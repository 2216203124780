<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_7783_146608)">
      <path
        d="M19.0628 6.50589C17.918 5.97028 16.694 5.58102 15.4143 5.35938C15.2571 5.64352 15.0735 6.0257 14.9469 6.32973C13.5866 6.12514 12.2387 6.12514 10.9034 6.32973C10.7768 6.0257 10.589 5.64352 10.4304 5.35938C9.14935 5.58102 7.92389 5.97171 6.77912 6.50873C4.4701 9.99796 3.84416 13.4005 4.15713 16.7548C5.68859 17.8984 7.17277 18.5932 8.63189 19.0478C8.99216 18.552 9.31347 18.0249 9.59027 17.4694C9.06309 17.2691 8.55817 17.0219 8.08108 16.7349C8.20765 16.6411 8.33146 16.5431 8.45107 16.4422C11.361 17.8033 14.5227 17.8033 17.3978 16.4422C17.5188 16.5431 17.6426 16.6411 17.7678 16.7349C17.2893 17.0233 16.783 17.2705 16.2558 17.4708C16.5326 18.0249 16.8526 18.5534 17.2142 19.0492C18.6747 18.5946 20.1603 17.8999 21.6918 16.7548C22.059 12.8663 21.0644 9.49503 19.0628 6.50589Z"
        stroke="currentColor"
        stroke-width="1.5"
      />
      <path
        d="M10.8263 12.8726L10.8262 12.8726V12.8842C10.8262 13.5619 10.3686 13.9427 9.98637 13.9427C9.6151 13.9427 9.14648 13.5569 9.14648 12.8842C9.14648 12.2057 9.60485 11.8242 9.98637 11.8242C10.3776 11.8242 10.8364 12.2134 10.8263 12.8726ZM16.7018 12.8726L16.7017 12.8726V12.8842C16.7017 13.5619 16.2441 13.9427 15.8619 13.9427C15.4906 13.9427 15.022 13.5569 15.022 12.8842C15.022 12.2057 15.4803 11.8242 15.8619 11.8242C16.2531 11.8242 16.712 12.2135 16.7018 12.8726Z"
        stroke="currentColor"
        stroke-width="1.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_7783_146608">
        <rect
          width="24"
          height="24"
          fill="currentColor"
          transform="translate(0.924805)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
