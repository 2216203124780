
import { defineComponent } from 'vue'
import PlanetIcon from '../Icons/PlanetIcon.vue'
import ChevronDownIcon from '../Icons/ChevronDownIcon.vue'

interface Locale {
  code: string
  name: string
  shortName: string
}

interface Data {
  locales: {
    [key: string]: Locale
  }
  modalShowed: boolean
}

export default defineComponent({
  name: 'BlockLocales',
  components: { PlanetIcon, ChevronDownIcon },
  data(): Data {
    return {
      locales: {
        en: {
          code: 'en',
          name: 'English',
          shortName: 'EN',
        },
        ko: {
          code: 'ko',
          name: '한국어',
          shortName: 'KO',
        },
      },
      modalShowed: false,
    }
  },
  computed: {
    currentLocale(): Locale {
      let locale = this.$i18n.locale
      return this.locales[locale]
    },
    localeList(): string[] {
      return this.$i18n.availableLocales.filter((item) => {
        return item !== this.$i18n.locale
      })
    },
  },
  methods: {
    getLocale(code: string): Locale {
      return this.locales[code]
    },
    changeCurrentLocale(locale: string) {
      this.$i18n.locale = locale
      this.closeModal()
    },
    closeModal() {
      if (window.innerWidth > 768) return
      this.modalShowed = false
    },
    openModal() {
      if (window.innerWidth > 768) return
      this.modalShowed = true
    },
  },
})
