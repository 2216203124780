import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderBlock = _resolveComponent("HeaderBlock")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_FooterBlock = _resolveComponent("FooterBlock")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeaderBlock),
    _createElementVNode("main", _hoisted_1, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(_component_FooterBlock)
  ], 64))
}