<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="3.9248"
      y="5"
      width="18"
      height="15"
      rx="4"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.9248 9.48438L12.1294 11.3176C12.6372 11.539 13.2141 11.5399 13.7226 11.32L17.9248 9.50299"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
